import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"


// markup
const IndexPage = () => {
  return (
    <main>
      <title >Home Page</title>
      <h1>
        <div id="logoline1">hair by</div>
        <div id="logoline2"> Edyta Szumicka</div>
      </h1>
      <p>
        Hello,<br />
        I'd love to give you a <span className="underline">fabulous hair make over</span>.
      </p>
      <p>
        I'm a Loreal colour & bleech expert with 20+ year of experience in cut and stlying.
      </p>
      <p>
        Get in touch for a booking.

      </p>

      <p>
        <br />
        Edyta XOXO
      </p>
      <br />
      <ul className="contacts">
        <li>Tel: <a href="tel:+447875372617">+44 7875 372 617</a></li>
        <li>WhatsApp: <a href="https://wa.me/447875372617" target="_blank" rel="noreferrer">+44 7875 372 617</a></li> 
        <li>Insta: <a href="https://www.instagram.com/hairbyedytaszumicka" target="_blank" rel="noreferrer">@hairbyedytaszumicka</a></li>
      </ul>
      <div className="photo-collection">
        
          <StaticImage className="column1 photo"
            src="../photos/insta-photo-1.png"
            alt="crop"
            placeholder="blurred"
            layout="fixed"
            width={240}
            height={240}
            quality={85}
          />
          <StaticImage className="column2 photo"
            src="../photos/insta-photo-2.png"
            alt="avantgade"
            placeholder="blurred"
            layout="fixed"
            width={240}
            height={240}
            quality={85}
            transformOptions={{ grayscale: true, }}
          />
        
        
        <StaticImage className="column1 photo"
          src="../photos/insta-photo-3.png"
          alt="colour"
          placeholder="blurred"
          layout="fixed"
          width={240}
          height={240}
          quality={85}
          transformOptions={{ grayscale: true }}
        />
        <StaticImage className="column2 photo"
          src="../photos/insta-photo-4.png"
          alt="platts"
          placeholder="blurred"
          layout="fixed"
          width={240}
          height={240}
          quality={85}
          transformOptions={{ grayscale: true }}
        />
        
      </div>
    </main>
  )
}

export default IndexPage
